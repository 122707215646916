import { useRouter, useRoute, createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import HomeView from '../components/Home/index.vue';
import LoginView from '../components/Login/index.vue';
import MainView from '../components/Main/index.vue';
import SmsTableView from '../components/SmsTable/index.vue';
// SmsTable
const routers = [{
        path: "/Login",
        name: "loginview",
        component: LoginView
    },
    {
        path: "/",
        redirect: "/Login"
    },
    {
        name: "mainview",
        path: "/main",
        component: MainView,
        children: [{
                name: "main_home_view",
                path: "home",
                component: HomeView
            },
            {
                name: "sms_table_view",
                path: "SmsTable",
                component: SmsTableView
            },
            {
                name: "sms_sendLogs_view",
                path: "SmsSendLogs",
                component: SmsTableView
            }
            // SmsTableView
            // sms_sendLogs_view
        ]
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes: routers
})
export default router