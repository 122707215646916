<template>
	<div class='home_contanier' v-loading="pageLoading">
		<div class="login_modules">
			<div class="form loginBox">
				<div class="title">
					<h2>登录账号</h2>
				</div>
				<div id="login_box">
					<!-- <input type="text" class="dl_zh" name="ZhangHao" placeholder="用户名" required=" " /> -->
					<div class="ipt_item_box">
						<div class="label">
							用户名
						</div>
						<div class="dl_ipt">
							<el-input class="dl_zh" type="text" v-model="users" placeholder="请输入管理账户"></el-input>
						</div>
					</div>
					<div class="ipt_item_box">
						<div class="label">
							密码
						</div>
						<div class="dl_ipt">
							<el-input class="dl_mm" v-model="password" type="password" placeholder="请输入密码"></el-input>
						</div>
					</div>
					<!-- <input class="dl_mm" type="password" name="MiMa" placeholder="密码" required=" " /> -->
					<div class="login_box">
						<el-button class="login_btn" type="primary" @click.stop="(eve)=>{Method.tologins(eve)}">登录</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import hex_md5 from '../../api/md5.js';
	import { onActivated, onMounted, onBeforeMount, ref, reactive, getCurrentInstance, watch, } from 'vue';
	import { useRouter } from 'vue-router';
	let refs1 = ref(0);
	let users = ref("");
	let password = ref("");
	let pageLoading = ref(false);
	let Method = reactive({});
	let { proxy } = getCurrentInstance();
	let router = useRouter();
	onActivated(() => {
		console.log('初始化');
		Method = reloads(0);
	});

	onMounted(() => {
		console.log('登录初始化', Method);
		// console.log('proxy', proxy,);
		Method = reloads(1);
	});
	function tologins() {
		console.log('等了', Method);
		// Method.tologins();
	}
	watch(users, (news, olds) => {
		// console.log('news,oldsusers', news, olds);
		news = news.replace(/[\*|\s|\<|\>].*?/g, " ");
		users.value = news.replace(" ", "");
	});
	watch(password, (news, olds) => {
		// console.log('news,oldspassword', news, olds);
		news = news.replace(/[\*|\<|\>].*?/g, "");
		password.value = news.replace(" ", "");
	});
	function reloads(idx = 0) {
		let _this = proxy;
		class Request {
			constructor() {

			}
			initData() {
				console.log('初始化 Login');
				this.relaodpages();
			}
			relaodpages() {
				let assocetoken = localStorage.getItem("assocetoken") || "";
				if (assocetoken != "") {
					this.tomainpage();
				}
			}
			tomainpage() {
				router.push("/main");
			}
			isflags = false;
			tologins() {
				// console.log('去登录', users.value.trim(), password.value.trim());
				console.log('获取内容:', _this, this, pageLoading.value);
				pageLoading.value = true;
				if (users.value == "") {
					console.log('去收尾空');
					proxy.$notify({
						title: '警告',
						message: '请输入账号！',
						type: 'warning',
						duration: 2000
					});
					pageLoading.value = false;
					return false;
				}
				if (password.value == "") {
					proxy.$notify({
						title: '警告',
						message: '请输入密码！',
						type: 'warning',
						duration: 2000
					});
					pageLoading.value = false;
					return false;
				}
				let data = {
					account: users.value || "",
					password: hex_md5(password.value) || "",
				};
				proxy.$axhttp({
					method: "post",
					url: "/api/user/login",
					data: data
				}).then((res) => {
					console.log('输出res:', res);
					// let { proxy } = getCurrentInstance();
					if (res.code == 200) {
						// console.log('输出', proxy.$notify);
						proxy.$notify({
							title: '成功',
							message: res.msg,
							type: 'success',
							duration: 2000
						});
						//             data.access_token
						// data.user_account
						// data.user_name
						localStorage.setItem("userInfo", JSON.stringify(res.data || {}));
						localStorage.setItem("assocetoken", res.data.access_token || "");
						setTimeout(() => {
							pageLoading.value = false;
							this.tomainpage();
						}, 1500);
					} else {
						proxy.$notify({
							title: '警告',
							message: res.msg,
							type: 'warning',
							duration: 2000
						});
						pageLoading.value = false;
					}
				}).catch((err) => {
					console.log('错误信息?', err);
					pageLoading.value = false;
				});
			}
		}
		let method = new Request();
		if (idx) {
			method.initData();
		}
		return method;
	}
</script>
<style lang='less' type='text/less' scoped>
	.home_contanier {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: url("../../assets/banner.jpg") no-repeat 100% 100%;
		background-size: cover;
		.login_modules {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			.loginBox {
				width: 420px;
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				padding: 30px;
				background: #fff;
				border-radius: 15px;
				.title {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 32px;
					h2 {
						font-size: 20px;
						font-weight: 600;
						color: #222;
					}
				}
				#login_box {
					margin-top: 30px;
					width: 100%;
					height: auto;
					.ipt_item_box {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						margin-bottom: 20px;
						.label {
							min-width: 86px;
							display: flex;
							justify-content: flex-end;
							align-items: center;
							height: 38px;
							color: #333;
							font-weight: 600;
						}
						.dl_ipt {
							flex: 1;
							height: 38px;
							margin-left: 15px;
							.el-input {
								height: 100%;
							}
						}
					}
					.login_box {
						width: 100%;
						height: 40px;
						line-height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 30px;
						.login_btn {
							width: 160px;
							height: 38px;
							border-radius: 8px;
						}
					}
				}
			}
		}
	}
</style>