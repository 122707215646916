import axios from 'axios';
import { createApp, getCurrentInstance } from 'vue'
// axios.interceptors.request.use(function(config) {
//     // 在发送请求之前做些什么
//     console.log('拦截请求');
//     return config;
// }, function(error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
// });
// console.log('axios.instance', axios, axios.interceptors);
let ignorepath = ["/login"]

// vue3 绑定全局
const install = (app) => {
    console.log('绑定全局:', app);
    // createApp()
    // let type = 1
    let host = "https://m.shefleekhair.com" //正式服务器域名
    const baseURL = process.env.NODE_ENV == "development" ? "http://127.0.0.1:5121" : host
        // app.config.globalProperties
    const instance = axios.create({
        baseURL: baseURL,
        timeout: 10000,
        headers: {
            // 'X-Custom-Header': 'foobar',
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Access-Control-Allow-Origin": "*",
        }
    })
    console.log('axios', axios.interceptors, instance.interceptors);
    instance.interceptors.request.use((config) => {
        console.log('请求拦截:', config);
        // config.url
        // header
        // config["header"]  = headers
        // header["Content-Type"]
        // header["Content-Type"]
        // headers["Content-Type"]
        // config["headers"]["Access-Control-Allow-Origin"] = "*"
        config["headers"]["Content-Type"] = config["header"] != undefined ? config["header"]["Content-Type"] != undefined ? config["header"]["Content-Type"] : 'application/x-www-form-urlencoded' : 'application/x-www-form-urlencoded'
            // console.log('请求的标头;', config);
        let assocetoken = localStorage.getItem('assocetoken') || ""
        let flags = true
        for (let i = 0; i < ignorepath.length; i++) {
            let ele = ignorepath[i];
            if (config.url.indexOf(ele) > -1) {
                flags = false
            }
        }
        if (flags) {
            if (assocetoken != null && assocetoken != "") {
                // Authorization
                console.log('获取对应的值:', localStorage.getItem('assocetoken'), config.headers);
                config.headers['Authorization'] = localStorage.getItem('assocetoken') || ""
                    // console.log('没有获取到值??', config.header['Authorization']);
            }
        }
        return config;
    }, (err) => {
        return Promise.reject(err);
    })
    instance.interceptors.response.use((respons, config) => {
        // console.log('拦截');
        if (respons.code == 200 || respons.status == 200) {
            // console.log('this', config);
            if (respons.data.code == 201) {
                localStorage.removeItem("assocetoken")
                localStorage.removeItem("userInfo")
                    // let { proxy } = getCurrentInstance();
                    // proxy.$notify({
                    //     title: '警告',
                    //     message: '登录状态失效，请重新登录',
                    //     type: 'warning',
                    //     duration: 2000
                    // });
                console.log('this', this);
                setTimeout(() => {
                    location.href = "/login"
                    return respons.data;
                }, 100);
            } else {
                return respons.data;
            }
        } else {
            return respons.data;
        }
        if (respons.code || respons.status) {
            return response.data;
        } else {
            return response
        }
    }, (err) => {
        console.log('err', err);
        return Promise.reject(err);
    })
    instance.defaults.withCredentials = false
    console.log('instance.defaults.headers:', instance.defaults.withCredentials);
    // instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    // 添加响应拦截器
    app.config.globalProperties.$axhttp = instance
        // app.prototype.$ax = instance
}
export default { install }
// class Request {
//     constructor() {
//         // console.log('如果请求速度过慢 可以在这里添加超时');
//     }
//     request(config) {
//         console.log('请求的config:', config);
//         return new Promise((resolve, reject) => {
//             instance({
//                 method: config.method.toLowerCase(),
//                 url: baseURL + config.url,

//                 data: config.method.toLowerCase() == "post" ? config.data : {},
//                 params: config.method.toLowerCase() == "get" ? config.params : {},
//                 // {
//                 //   config.method == "get" ? ...config.data : ...{}
//                 // },
//                 headers: {
//                     ...config.header,
//                 }
//             }).then((res) => {
//                 console.log('输出res:', res);
//                 resolve(res.data, config)
//             }).catch((err) => {
//                 console.log('错误信息?', err);
//                 reject(err)
//             })
//         })
//     }
// }

// export default Request