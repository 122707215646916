<template>
	<!-- <router-link to="/">Home</router-link>  -->
	<!-- <router-link to="/main">About</router-link> -->
	<router-view></router-view>
</template>
<script>
	export default {
		name: "app"
	}
</script>
<style lang='less' type='text/less'>
	@import url("./assets/styles/main.less");
	#app {
		width: 100%;
		height: 100%;
	}
</style>
