<template>
	<div class='sms_table_contanier' v-loading.fullscreen.lock="pageLoading">
		<div class="sms_table_title">
			<h1>Send Messaging Logs</h1>
		</div>
		<div class="search_tabs">
			<div class="search_contanier">

				<div class="select_item_box select_filter">
					<div class="select_label">短信ID</div>
					<div class="select_box">
						<el-input v-model="search_data.searchMsgSid" placeholder="搜索短信ID" :clearable="true"></el-input>
					</div>
					<div class="config_btns">
						<el-button class="search_sid" type="primary" @click="(eve)=>{Method.searchSid(eve)}" :disabled="searchFlag" plain>搜索</el-button>
						<!-- :disabled="searchSid.length > 0 ? false:true"  -->
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">开始时间</div>
					<div class="select_box">
						<el-date-picker v-model="search_data.select_start_time" type="date" placeholder="选择开始日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD">
						</el-date-picker>
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">结束时间</div>
					<div class="select_box">
						<el-date-picker v-model="search_data.select_end_time" type="date" placeholder="选择结束日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD">
						</el-date-picker>
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">发信号码</div>
					<div class="select_box">
						<el-input v-model="search_data.searchFrom" placeholder="搜索发信号码"></el-input>
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">收信号码</div>
					<div class="select_box">
						<el-input v-model="search_data.searchTo" placeholder="搜索收信号码"></el-input>
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">客户状态</div>
					<div class="select_box">
						<el-select v-model="search_data.customStatus" filterable placeholder="请选择客户状态">
							<el-option v-for="item in  SelectOptions.value" :key="item.value" :label="item.label" :value="item.value" />
							<!-- 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） -->
						</el-select>
					</div>
				</div>
				<div class="select_item_box">
					<div class="select_label">短信状态</div>
					<div class="select_box">
						<el-select v-model="search_data.MessageStatus" filterable placeholder="请选择短信状态">
							<el-option v-for="item in  MessageOptions.value" :key="item.value" :label="item.label" :value="item.value" />
							<!-- 默认的 发送失败的  过滤的  空号（录入的号码 看看之后能不能识别出来） -->
						</el-select>
					</div>
				</div>
				<div class="search_btn">
					<el-button class="search_sid" type="primary" @click="(eve)=>{Method.filterSearch(eve)}" plain>筛选</el-button>
					<el-button class="search_sid" type="primary" @click="(eve)=>{
            Method.searchRefresh(eve)
          }" plain>重置</el-button>
				</div>
			</div>
		</div>
		<div class="configuration_box">
			<el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.doubleSend(eve)}" plain round>群发短信</el-button>
			<el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.FileSend(eve)}" plain round>文件发送</el-button>
			<el-button class="configuration_btn" type="primary" @click="(eve)=>{Method.FileUpload(eve)}" plain round>上传文件</el-button>
		</div>
		<div class="table_boxs">
			<el-table class="table_main" ref="multipleTable" :data="tableData.value" tooltip-effect="dark" :max-height="530" @selection-change="(eve)=>{Method.handleSelectionChange(eve)}" :highlight-current-row="true" :border="true">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="Form" width="120" prop="From">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="To" width="120" prop="To">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="短信内容" prop="Body">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="短信状态" width="120" prop="Status">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="发送时间" width="120" prop="SentDate">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="短信ID" width="160" prop="Sid">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="媒体信息" width="120">
					<template #default="scope">
						<div class="media" v-if="scope.row.MediaUrl0 != ''">
							<img class="img_box" :src="scope.row.MediaUrl0">
						</div>
						<!-- <span style="margin-left: 10px">{{ scope.row.date }}</span> -->
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="短信服务ID" width="120" prop="messaging_service_sid">
				</el-table-column>
				<!-- width="260" -->
				<el-table-column label="操作">
					<template #default="scope">
						<!-- <el-button @click="Method.handleConfigs(scope.$index, scope.row,'edit')" plain round>编辑</el-button> -->

						<el-button type="danger" @click="()=>{Method.handleConfigs(scope.$index, scope.row,'filterateCustom')}" v-if="scope.row.message_status == '1'" plain round>过滤用户</el-button>
						<el-button type="primary" @click="()=>{Method.handleConfigs(scope.$index, scope.row,'sendmsg')}" plain round>发送短信</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination_box">
				<el-pagination class='pagination_main' background layout="prev, pager, next" :pager-count="7" :total="paginatotal" :page-sizes="[10, 20, 30, 40, 50, 100]" :default-page-size="50" :current-page="page_current"
					@current-change="(num)=>{Method.pageChange(num)}">
				</el-pagination>
				<div class="total_num">
					当前总数{{ paginatotal }}条
				</div>
				<!-- paginatotal.value page-sizes="[10, 20, 30, 40, 50, 100]" -->
			</div>
		</div>
		<!-- 去发送 -->
		<el-dialog v-model="dialogFormVisible" :title="dialogConfigura.title || '发送短信'" @close="Method.dialogCancle" width="40%">
			<el-form :model="dialogConfigura">
				<el-form-item label="发信站点" :label-width="formLabelWidth" class="form_item_box">
					<div class="send_sms_item_box">
						<el-select @change="(eve)=>{Method.complies(eve)}" filterable class="ipt_box" v-model="dialogConfigura.sms_site" slot="prepend" placeholder="请选择发送短信账号">
							<el-option :label="ele.send_name" :value="ele.id" v-for='ele,idx in sms_info.value' :key='idx'></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item label="发信类型" :label-width="formLabelWidth" class="form_item_box" v-if="dialogConfigura.sms_site != ''">
					<div class="send_sms_item_box">
						<el-select filterable class="ipt_box" v-model="dialogConfigura.sms_select_type" slot="prepend" placeholder="请选择发送短信类型">
							<el-option :label="ele.label" :value="ele.value" v-for='ele,idx in sms_send_type_arr.value' :key='idx'></el-option>
						</el-select>
					</div>
				</el-form-item>
				<!-- sms_send_type_arr -->
				<el-form-item label="发信电话" :label-width="formLabelWidth" class="form_item_box" v-if="dialogConfigura.sms_select_type == 1">
					<div class="send_sms_item_box">
						<el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.from_number" type="type" autosize placeholder="请输入发信电话">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="短信服务" :label-width="formLabelWidth" class="form_item_box" v-if="dialogConfigura.sms_select_type == 2">
					<div class="send_sms_item_box">
						<el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.messaging_service_sid" type="type" autosize placeholder="请输入短信服务ID（MGXXXX）">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="客户电话" :label-width="formLabelWidth" class="form_item_box" v-if="dialogConfigura.sendType == 1">
					<div class="send_sms_item_box collapse_continaer_box">
						<!-- 放一批 选中的用户号码 -->
						<!-- <el-input class="ipt_box" :disabled="true" v-model="dialogConfigura.to_number" type="type" autosize placeholder="请选择收信号码">
						</el-input> -->
						<el-collapse v-model="dialogConfigura.activeNames" @change="Method.to_numberhandleChange" accordion>
							<el-collapse-item title="客户电话" name="1">
								<div class="collapse_box">
									<span class="collent_item" v-for='ele,idx in select_checkbox' :key='idx'>
										<el-badge class="item numbers">
											<el-button>{{ Method.formatNumber(ele.From) }}</el-button>
										</el-badge>
										<!-- <span class="icon_box">
											<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
												<path fill="currentColor"
													d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
												</path>
											</svg>
										</span> -->
									</span>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</el-form-item>

				<el-form-item label="上传文件" :label-width="formLabelWidth" class="form_item_box" v-if="dialogConfigura.sendType == 2">
					<div class="send_sms_item_box">
						<el-button class="uploadFile" @click="Method.uploadChangeHandle">{{ upload_send_csv_file.fileName == '' ?'请选择上传文件': upload_send_csv_file.fileName }}</el-button>
						<!-- <el-input v-if="upload_send_csv_file.fileName != ''" v-model="upload_send_csv_file.fileName" type="button" placeholder="请选择上传文件" @click="Method.uploadChangeHandle" readonly></el-input> -->
						<!-- <el-upload ref="uploadref" class="upload-demo" :limit="1" :auto-upload="false" :show-file-list="false" @handleStart="Method.handleFile" :http-request="Method.handHttpFile"
							@abort="Method.handleFile" accept=".csv,.xlsx,.xls">
							
						</el-upload> -->
					</div>
				</el-form-item>
				<!-- v-if="dialogConfigura.sendType == 1" -->
				<el-form-item label="发送内容" :label-width="formLabelWidth" class="form_item_box">
					<div class="send_sms_item_box">
						<div class="sms_main_box">
							<el-input v-model="dialogConfigura.send_msg" type="textarea" :autosize="{ minRows: 4, maxRows: 8}" maxlength="160" placeholder="请输入发送内容">
							</el-input>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle">{{dialogConfigura.cancleTitle || "取消"}}</el-button>
					<el-button type="primary" @click="(eve)=>{Method.dialogcConfirm(eve)}">{{dialogConfigura.entryTitle || "确定"}}</el-button>
				</span>
			</template>
		</el-dialog>
		<el-dialog class="dialog_mainaer" v-model="dialogFormVisible1" :title="dialogConfigura1.title || '上传短信记录'" @close="Method.dialogCancle1" width="30%">
			<div class="main_container">
				<div class="toast_box">
					{{ dialogConfigura1.dialogType == 1 ? "上传Twilio官网短信记录需要较长时间，如无必要谨慎上传或分段上传！":`过滤用户${dialogConfigura1.From}后，用户会进入过滤组，所有批量操作都会受到影响，慎重使用！` }}
				</div>
				<div class="upload_file_box" v-if="dialogConfigura1.dialogType == 1">
					<div class="label">上传记录</div>
					<div class="btn_box">
						<!-- <el-button v-if="upload_send_csv_file1.fileName == ''" class="uploadFile" @click="Method.uploadChangeHandle1">请选择上传文件</el-button>
						<el-input class="uploadFile" v-if="upload_send_csv_file1.fileName != ''" v-model="upload_send_csv_file1.fileName" type="button" placeholder="请选择上传文件" @click="Method.uploadChangeHandle1" readonly></el-input> -->
						<el-button class="uploadFile" @click="Method.uploadChangeHandle1">{{ upload_send_csv_file1.fileName == '' ? '请选择上传文件' : upload_send_csv_file1.fileName }}</el-button>
					</div>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="Method.dialogCancle1">{{dialogConfigura.cancleTitle || "取消"}}</el-button>
					<el-button type="primary" @click="(eve)=>{Method.dialogcEntry(eve)}">{{dialogConfigura.entryTitle || "确定"}}</el-button>
				</span>
			</template>
		</el-dialog>
		<input type="file" accept=".csv,.xlsx,.xls" ref="upload_files" class="upload_dm" @change="Method.handleFile">
		<input type="file" accept=".csv,.xlsx,.xls" ref="upload_files1" class="upload_dm" @change="Method.handleFile1">
		<!-- :hide-on-single-page="1" -->
		<!-- upload_files1 -->
	</div>
</template>
<script setup>
	import { onActivated, onMounted, ref, reactive, getCurrentInstance, watch } from 'vue';
	let refs1 = ref(0);
	let Method = reactive({});
	let tableData = reactive([]);
	// let searchSid = ref("");
	let searchSendNumber = ref("");
	let searchacceptNumber = ref("");
	let select_start_time = ref("");
	let select_end_time = ref("");
	let paginatotal = ref(0);
	let searchFlag = ref(true);
	let table_page = ref(1);
	let { proxy } = getCurrentInstance();
	let pageLoading = ref(false);
	let page_size = ref(50);
	let page_lens = ref(1);
	let page_current = ref(1);
	let SelectOptions = reactive([]);
	let MessageOptions = reactive([]);
	let customStatus = ref("");
	let dialogFormVisible = ref(false);
	let dialogFormVisible1 = ref(false);
	let dialogConfigura = reactive({});
	let dialogConfigura1 = reactive({});
	let formLabelWidth = ref(120);
	let sms_info = reactive({});
	let search_data = reactive({});
	let select_flag = ref(0);
	let sms_info_config = reactive({});
	let sms_send_type_arr = reactive([]);
	let fileList = reactive([]);
	let upload_send_csv_file = reactive({
		fileName: ""
	});
	let upload_send_csv_file1 = reactive({
		fileName: ""
	});
	let searchType = ref(1);
	let search_filter = reactive({});
	let select_checkbox = reactive([]);
	// select_start_time
	// ="select_end_time
	onActivated(() => {
		console.log('dom加载');
		Method = reloads(0);
	});
	onMounted(() => {
		console.log('home初始化');
		Method = reloads(1);
	});
	function reloads(idx = 0) {
		let _this = this;
		class Request {
			constructor() {

			}
			initData() {
				console.log('初始化数据');
				this.reloadTable();
				this.reload_SMS_type_List();
				// this.doubleSend();
				// this.FileSend();
				SelectOptions.value = [
					{
						id: 1,
						value: "1",
						label: "请选择客户状态"
					},
					{
						id: 2,
						value: "2",
						label: "过滤用户"
					},
					{
						id: 3,
						value: "3",
						label: "STOP屏蔽"
					},
					{
						id: 4,
						value: "4",
						label: "UNSUBSCRIBE屏蔽"
					},
					// 空号的暂时保留 做两份table使用
				];
        // 1 delivered  2 undelivered  3 received 4 failed
				MessageOptions.value = [
					{
						id: 1,
						value: "",
						label: "请选择短信状态"
					},
					{
						id: 2,
						value: "1",
						label: "delivered"
					},
					{
						id: 3,
						value: "2",
						label: "undelivered"
					},
					{
						id: 4,
						value: "3",
						label: "received"
					},
          {
						id: 4,
						value: "4",
						label: "failed"
					},
				];
				// delivered   undelivered  received
				// 默认的 发送失败的  过滤的  空号
			}
			reloadTable() {
				pageLoading.value = true;
				if (searchType.value == 1) {
					let limit = page_size.value;
					proxy.$axhttp({
						method: "get",
						url: "/api/user/twiliotable/logs",
						params: {
							"limit": limit,
							"offset": (page_current.value - 1) * limit,
						}
					}).then((res) => {
						console.log('输出res:', res);
						if (res.code == 200) {
							// console.log('table表格:', res.data, tableData.value);
							tableData.value = [...res.data.allCont];
							paginatotal.value = res.data.total;
							// data.total
							page_lens.value = Math.ceil(res.data.total / limit);
							// tableData.value;
						}
						pageLoading.value = false;
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
				if (searchType.value == 2) {
					let limit = page_size.value;
					let param_data = {
						"limit": limit,
						"offset": (page_current.value - 1) * limit,
					};
					if (search_data.searchMsgSid != "") {
						param_data["searchMsgSid"] = search_data.searchMsgSid;
					}
					param_data["type"] = "a";
					proxy.$axhttp({
						method: "get",
						url: "/api/user/twiliotable/logs",
						params: param_data
					}).then((res) => {
						console.log('输出res:', res);
						if (res.code == 200) {
							// console.log('table表格:', res.data, tableData.value);
							tableData.value = [...res.data.allCont];
							paginatotal.value = res.data.total;
							// data.total
							page_lens.value = Math.ceil(res.data.total / limit);
							// tableData.value;
						}
						pageLoading.value = false;
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
				if (searchType.value == 3) {
					let limit = page_size.value;
					// let search_filter =
					//           search_filter.start_time
					// search_filter.end_time
					// search_filter.from
					// search_filter.to
					// search_filter.customStatus
					let params_data = {
						"limit": limit,
						"offset": (page_current.value - 1) * limit,
					};
					params_data["type"] = "b";
					if (search_filter.start_time != "") {
						params_data["start_time"] = search_filter.start_time || "";
					}
					if (search_filter.end_time != "") {
						params_data["end_time"] = search_filter.end_time || "";
					}
					if (search_filter.from != "") {
						params_data["searchFrom"] = search_filter.from || "";
					}
					if (search_filter.to != "") {
						params_data["searchTo"] = search_filter.to || "";
					}
					if (search_filter.customStatus != "") {
						params_data["customStatus"] = search_filter.customStatus || "";
					}
          if (search_filter.MessageStatus != "") {
						params_data["searchMessageStatus"] = search_filter.MessageStatus || "";
					}
          // MessageStatus
					proxy.$axhttp({
						method: "get",
						url: "/api/user/twiliotable/logs",
						params: params_data
					}).then((res) => {
						console.log('输出res:', res);
						if (res.code == 200) {
							// console.log('table表格:', res.data, tableData.value);
							tableData.value = [...res.data.allCont];
							paginatotal.value = res.data.total;
							// data.total
							page_lens.value = Math.ceil(res.data.total / limit);
							// tableData.value;
						}
						pageLoading.value = false;
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
			}
			reload_SMS_type_List() {
				proxy.$axhttp({
					method: "get",
					url: "/api/sms/getSMSList",
					params: {}
				}).then((res) => {
					console.log('输出res:', res);
					if (res.code == 200) {
						sms_info.value = [...res.data];
						// res.data
						// "from_number": "+16076227999",
						// "id": 1,
						// "send_name": "ashimaryhair"
					}
				}).catch((err) => {
					console.log('错误信息?', err);
				});
			}
			doubleSend() {
				console.log('群发');
				// 在这里判断是否多选 
				// select_checkbox
				if (select_checkbox.length > 0) {
					dialogConfigura.sendType = 1; //1是 table选择群发
					dialogConfigura.sms_site = "";
					dialogConfigura.activeNames = "";
					dialogConfigura.fileName = "";
					dialogFormVisible.value = true;
				} else {
					proxy.$message({
						showClose: true,
						type: 'info',
						center: true,
						message: '群发短信需要选中表格中的收信单元格！',
						duration: 2000
					});
				}
			}
			FileSend() {
				dialogConfigura.sendType = 2;  // 2 是文件上传群发
				dialogConfigura.sms_site = "";
				dialogConfigura.activeNames = "";
				dialogConfigura.fileName = "";
				dialogFormVisible.value = true;
			}
			FileUpload() {
				// 上传文件
				dialogConfigura1["title"] = "上传短信记录";
				dialogConfigura1["dialogType"] = "1";
				dialogFormVisible1.value = true;
			}
			dialogCancle() {
				console.log('取消');
				dialogFormVisible.value = false;
				for (const key in dialogConfigura) {
					if (Object.hasOwnProperty.call(dialogConfigura, key)) {
						console.log('循环内容:', key, dialogConfigura, dialogConfigura[key]);
						// let ele = dialogConfigura[key];
						// ele = "";
						dialogConfigura[key] = "";
					}
				}
				//  upload_files
				proxy.$refs.upload_files.value = "";
				proxy.$refs.upload_files.value = null;
				proxy.$refs.upload_files.innerText = "";
				proxy.$refs.upload_files.innerText = null;
				upload_send_csv_file.fileObj = {};
				upload_send_csv_file.fileName = "";
				// proxy.$refs.multipleTable.clearSelection();
				// clearSelection 清空 默认选择
				// toggleRowSelection 选中当前行
				// select_checkbox = [];
			}
			dialogCancle1() {
				console.log('关闭');
				// dialogConfigura1
				dialogFormVisible1.value = false;
				for (const key in dialogConfigura1) {
					if (Object.hasOwnProperty.call(dialogConfigura1, key)) {
						if (key == "dialogType") {
							dialogConfigura1[key] = "1";
							continue;
						}
						dialogConfigura1[key] = "";
					}
				}
				proxy.$refs.upload_files1.value = "";
				proxy.$refs.upload_files1.value = null;
				proxy.$refs.upload_files1.innerText = "";
				proxy.$refs.upload_files1.innerText = null;
				upload_send_csv_file1.fileObj = {};
				upload_send_csv_file1.fileName = "";
			}
			dialogcEntry() {
				console.log('确定 按钮');
				// dialogFormVisible1.value = false;
				if (pageLoading.value) {
					return false;
				}
				pageLoading.value = true;
				if (dialogConfigura1.dialogType == 1) {
					if (upload_send_csv_file1.fileName == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请先上传文件',
							duration: 2000
						});
					}
					// console.log('上传文件');
					// proxy.$refs.upload_files1.click();
					let files = upload_send_csv_file1.fileObj;
					let dataForm = new FormData();
					dataForm.append("files", files);
					proxy.$axhttp({
						method: "post",
						url: "/api/files/upload_all_csv_file",
						header: {
							"Content-Type": "multipart/form-data;"
						},
						data: dataForm
					}).then((res) => {
						console.log('输出res:', res);
						if (res.code == 200) {
							pageLoading.value = false;
							dialogFormVisible1.value = false;
							proxy.$message({
								showClose: true,
								type: 'success',
								message: `添加成功！总长度${res.data.max_coutns_total || 0} 添加成功${res.data.success_counts_total || 0}条 添加失败${res.data.fail_counts_total || 0}条 筛选条件${res.data.filter_counts_total || 0}条`,
								duration: 2000
							});
						}
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
				if (dialogConfigura1.dialogType == 2) {
					console.log('过滤用户');
					// ["id"]
					// From"]
					let data = {
						"id": dialogConfigura1["id"] || "",
						"From": dialogConfigura1["From"] || ""
					};
					proxy.$axhttp({
						method: "post",
						url: "/api/msg/status/filterate",
						data: data
					}).then((res) => {
						console.log('输出res:', res);
						pageLoading.value = false;
						if (res.code == 200) {
							proxy.$message({
								showClose: true,
								type: 'success',
								message: '编辑成功！',
								duration: 2000
							});
							setTimeout(() => {
								// pageLoading.value = false;
								dialogFormVisible1.value = false;
								this.reloadTable();
							}, 1500);
						} else {
							proxy.$message({
								showClose: true,
								type: 'success',
								message: res.msg,
								duration: 2000
							});
						}
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
				// 上传文件 
			}
			dialogcConfirm() {
				console.log('确认');
				// upload_send_csv_file.fileObj
				// upload_send_csv_file.filename
				//         dialogConfigura.sms_select_type
				// dialogConfigura.sms_site
				// dialogConfigura.sendType
				// dialogConfigura.messaging_service_sid
				// dialogConfigura.from_number
				let sendType = dialogConfigura.sendType;
				if (pageLoading.value) {
					return false;
				}
				pageLoading.value = true;
				if (sendType == 1) {
					// let { proxy } = getCurrentInstance();
					if (dialogConfigura.sms_site == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请选择站点！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					if (dialogConfigura.sms_select_type == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请选择发信类型！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					if (dialogConfigura.send_msg == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请填写发送内容！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					let data = {};
					let phone_list_number = {};
					select_checkbox.forEach((ele, idx) => {
						if (phone_list_number[ele.From] == undefined) {
							phone_list_number[ele.From] = 1;
						}
					});
					data["sms_site_type"] = dialogConfigura.sms_site || "";
					data["sms_server_select"] = dialogConfigura.sms_select_type || "";
					data["body"] = dialogConfigura.send_msg || "";
					// data  sms_select_type
					data["phone_list_number"] = Object.keys(phone_list_number).join(",");
					// dataForm.append("sms_server_select", dialogConfigura.sms_select_type || "");
					// dataForm.append("body", dialogConfigura.send_msg || "");
					// console.log('上传的手机号:', data);
					proxy.$axhttp({
						method: "post",
						url: "/api/pushList/list_to_number",
						data: data
					}).then((res) => {
						// console.log('输出res:', res);
						pageLoading.value = false;
						if (res.code == 200) {
							proxy.$message({
								showClose: true,
								type: 'success',
								message: res.msg,
								duration: 2000
							});
							setTimeout(() => {
								dialogFormVisible.value = false;
							}, 1500);
						}
					}).catch((err) => {
						// console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
				if (sendType == 2) {
					if (upload_send_csv_file.fileName == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请选择上传的文件！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					if (dialogConfigura.sms_site == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请选择站点！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					if (dialogConfigura.sms_select_type == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请选择发信类型！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					if (dialogConfigura.send_msg == "") {
						proxy.$message({
							showClose: true,
							type: 'info',
							center: true,
							message: '请填写发送内容！',
							duration: 2000
						});
						pageLoading.value = false;
						return false;
					}
					// 发信类型
					// 发送内容
					let dataForm = new FormData();
					dataForm.append("files", upload_send_csv_file.fileObj);
					dataForm.append("sms_site_type", dialogConfigura.sms_site || "");
					dataForm.append("sms_server_select", dialogConfigura.sms_select_type || "");
					dataForm.append("body", dialogConfigura.send_msg || "");
					proxy.$axhttp({
						method: "post",
						url: "/api/files/upload_to_number",
						header: {
							"Content-Type": "multipart/form-data;"
						},
						data: dataForm
					}).then((res) => {
						console.log('输出res:', res);
						pageLoading.value = false;
						if (res.code == 200) {
							proxy.$message({
								showClose: true,
								type: 'success',
								message: res.msg,
								duration: 2000
							});
							setTimeout(() => {
								dialogFormVisible.value = false;
							}, 1500);
						}
					}).catch((err) => {
						console.log('错误信息?', err);
						pageLoading.value = false;
					});
				}
			}
			formatNumber(numbers) {
				let strs = '';
				// numbers.
				strs = numbers.replace(/(\d{3})\d{4}(\d{4})/g, '$1****$2');
				return strs;
			}
			searchSid() {
				// console.log('搜搜sid:', search_data.searchMsgSid);
				if (search_data.searchMsgSid != "") {
					searchType.value = "2";
				} else {
					searchType.value = "1";
				}
				this.reloadTable();
				// proxy.$axhttp({
				// 	method: "get",
				// 	url: "/api/",
				// 	params: {}
				// }).then((res) => {
				// 	console.log('输出res:', res);
				// }).catch((err) => {
				// 	console.log('错误信息?', err);
				// });
			}
			complies() {
				if (dialogConfigura.sms_site.value != "") {
					// console.log('sms_info.value', sms_info.value.length);
					for (let i = 0; i < sms_info.value.length; i++) {
						const ele = sms_info.value[i];
						// console.log('遍历:',ele.send_name,dialogConfigura.sms_site.value);
						if (ele.id == dialogConfigura.sms_site) {
							// form_number.value = ele["from_number"];
							sms_info_config = { ...ele };
						}
					}
					select_flag.value = 1;
					let from_arr = [
						{
							id: 1,
							value: "1",
							label: "Phone Number"
						},
						{
							id: 2,
							value: "2",
							label: "Messaging Service"
						}
					];
					sms_send_type_arr.value = [...from_arr];
				} else {
					select_flag.value = 0;
				}
			}
			filterSearch() {
				//  searchSendNumber
				// searchacceptNumber
				// select_start_time
				// select_end_time
				// select_start_time
				// select_end_time
				//  search_data.select_start_time
				//  search_data.select_end_time
				// console.log('搜搜sid:', search_data, search_data.select_start_time, search_data.select_end_time);
				let select_start_time = search_data.select_start_time || "";
				let select_end_time = search_data.select_end_time || "";
				let searchFrom = search_data.searchFrom || "";
				let searchTo = search_data.searchTo || "";
				let customStatus = search_data.customStatus || "";
				let MessageStatus = search_data.MessageStatus || "";
				// console.log('时间格式化:', select_start_time, select_end_time, searchFrom, searchTo, customStatus);
				search_filter.start_time = select_start_time || "";
				search_filter.end_time = select_end_time || "";
				search_filter.from = searchFrom || "";
				search_filter.to = searchTo || "";
				search_filter.customStatus = customStatus || "";
				search_filter.MessageStatus = MessageStatus || "";
				searchType.value = "3";
				// console.log('搜索 筛查:', this);
				// console.log('搜索 筛查2:', proxy.Method);
				this.reloadTable();
				// console.log('内容:', searchacceptNumber.value, select_start_time.value, select_end_time.value);
			}
			searchRefresh() {
				// console.log('搜索 refresh 重置');
				searchType.value = "1";
				for (const key in search_data) {
					if (Object.hasOwnProperty.call(search_data, key)) {
						search_data[key] = "";
					}
				}
				this.reloadTable();
			}
			handleSelectionChange(res) {
				// console.log('获取选中的值:', res);
				select_checkbox = [...res];
			}
			uploadChangeHandle() {
				// console.log('上传文件', proxy);
				// console.log('proxy', proxy.$refs.upload);
				// console.log('sas1', proxy.$refs.upload_files);
				// .upload_files
				proxy.$refs.upload_files.click();
				// upload
			}
			uploadChangeHandle1() {
				// console.log('上传文件', proxy);
				// console.log('proxy', proxy.$refs.upload);
				// console.log('sas1', proxy.$refs.upload_files);
				// .upload_files
				proxy.$refs.upload_files1.click();
				// upload
			}
			handleFile(file) {
				// console.log('选择的文件:', file);
				fileList = file.target.files;
				// console.log('fileList', fileList[0]);
				// fileList[0].name filename
				upload_send_csv_file.fileObj = fileList[0];
				upload_send_csv_file.fileName = fileList[0].name;
				// console.log('upload_send_csv_file', upload_send_csv_file);
				proxy.$refs.upload_files.value = "";
				proxy.$refs.upload_files.value = null;
				proxy.$refs.upload_files.innerText = "";
				proxy.$refs.upload_files.innerText = null;
				// let urls = window.URL.createObjectURL(fileList[0]);
				// console.log(urls, fileList);//这个就是选中文件信息
			}
			handleFile1(file) {
				fileList = file.target.files;
				// console.log('fileList', fileList[0]);
				// fileList[0].name filename
				upload_send_csv_file1.fileObj = fileList[0];
				upload_send_csv_file1.fileName = fileList[0].name;
				// console.log('upload_send_csv_file', upload_send_csv_file);
				proxy.$refs.upload_files1.value = "";
				proxy.$refs.upload_files1.value = null;
				proxy.$refs.upload_files1.innerText = "";
				proxy.$refs.upload_files1.innerText = null;
				// this.
			}
			handHttpFile(res) {
				// dialogFormVisible1.value = false;

			}
			handleConfigs(index, rows, type) {
				console.log('功能按钮', index, rows, type);
				//  'del'
				// 'sendmsg'
				if (type == 'filterateCustom') {
					console.log('过滤用户', rows, rows.id, rows.From);
					// id From
					dialogConfigura1["dialogType"] = "2";
					dialogConfigura1["id"] = rows.id || "";
					dialogConfigura1["From"] = rows.From || "";
					dialogConfigura1["title"] = "过滤用户";
					dialogFormVisible1.value = true;
				}
				if (type == 'sendmsg') {
					console.log('发送短信');
					select_checkbox = [];
					select_checkbox.push({ ...rows });
					proxy.$refs.multipleTable.toggleRowSelection(rows, true);
					// toggleRowSelection 选中当前行
					// 直接走 单个群发
					this.doubleSend();
				}
			}
			handleEdit(index, rows) {
				console.log('编辑', index, rows);
			}
			to_numberhandleChange(val) {
				console.log('展开');
				console.log(val);
			}
			handleDel() {

			}
			handleSend() {

			}
			pageChange(num) {
				console.log('num', num, page_current.value);
				page_current.value = num;
				this.reloadTable();
			}
		}
		let method = new Request();
		if (idx) {
			method.initData();
		}
		return method;
	}
	// searchSid
	// dialogConfigura.sms_select_type
	watch(search_data, (nowval, oldval) => {
		// console.log('监听searchSid', nowval, oldval);
		// SM
		console.log('nowval.searchSid', nowval.searchMsgSid);
		// searchSid
		if (!!nowval.searchMsgSid) {
			if (nowval.searchMsgSid.length >= 34) {
				searchFlag.value = false;
			}
		}
	});
	watch(dialogConfigura, (nowval, oldval) => {
		// console.log('监听searchSid', nowval, oldval, sms_info_config);
		//     sms_info_config.from_number
		// sms_info_config.messaging_service_sid
		if (nowval.sms_select_type == "1") {
			// id: 1,
			// value: "1",
			// label: "Phone Number"

			dialogConfigura.from_number = sms_info_config.from_number;
		}
		if (nowval.sms_select_type == "2") {
			dialogConfigura.messaging_service_sid = sms_info_config.messaging_service_sid;
		}
	})
</script>
<style lang='less' type='text/less' scoped>
	.sms_table_contanier {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 10px;
		.sms_table_title {
			width: 100%;
			height: 44px;
			font-size: 32rpx;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin: 15px 0;
			padding-left: 30px;
			h1 {
				font-size: 32rpx;
				color: rgb(18, 28, 45);
				font-weight: 700;
				display: block;
				line-height: 44px;
			}
		}
		.search_tabs {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 20px 10px;
			.search_contanier {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: wrap;

				.select_item_box {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 15px;
					.select_label {
						min-width: 120px;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						height: 32px;
						font-size: 14px;
						color: #333;
					}
					.select_box {
						height: 32px;
						margin-left: 15px;
						// height: 220px;
						min-width: 220px;
					}
					&.select_filter {
						width: 100%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						.select_box {
							width: 340px;
						}
						.config_btns {
							margin-left: 20px;
						}
					}
				}
			}
			.search_btn {
				margin-left: 30px;
			}
		}
		.configuration_box {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 15px 20px;
			.configuration_btn {
				margin-right: 10px;
			}
		}
		.table_boxs {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			.pagination_box {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin: 15px 0;
				.pagination_main {
				}
				.total_num {
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-size: 14px;
					padding-left: 30px;
				}
			}
			.table_main {
				width: 100%;
				.media {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					.img_box {
						width: 32px;
						height: 32px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
		.el-overlay {
			.el-dialog__body {
				width: 100%;
				padding: 0 30px;
				.el-form {
					.el-form-item {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 50%;
						.el-form-item__content {
							max-width: 214px;
							flex: 0.5;
						}
						&:last-of-type {
							align-items: flex-start;
						}
					}
				}
			}
			.el-dialog__footer {
				border-top: 1px solid #eee;
			}
			.form_item_box {
				.el-form-item__content {
					min-width: 214px;
					display: flex;
					justify-content: center;
					align-items: center;
					.send_sms_item_box {
						width: 100%;
						.ipt_box {
							width: 100%;
						}
						.uploadFile {
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
						&.collapse_continaer_box {
							width: 100%;
							.el-collapse {
								width: 100%;

								.el-collapse-item {
									width: 100%;
									.collapse_box {
										width: 100%;
										display: flex;
										justify-content: flex-start;
										align-items: flex-start;
										flex-wrap: wrap;
										.collent_item {
											margin-right: 6px;
											margin-bottom: 12px;
										}
										.el-badge {
											.el-button {
												padding: 6px 10px;
											}
										}
									}
								}
							}
							.collent_item {
								display: flex;
								justify-content: flex-start;
								align-items: center;
							}
						}
					}
				}
			}
		}
		.el-form {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			.form_item_box {
				width: 50%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
		.upload_dm {
			width: 0;
			height: 0;
			display: none;
		}
		.dialog_mainaer {
			.main_container {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.toast_box {
					width: 100%;
					flex-wrap: wrap;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 10px 0;
					padding-left: 30px;
				}
				.upload_file_box {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-top: 10px;
					overflow: hidden;
					.label {
						min-width: 120px;
						height: 100%;
						display: flex;
						justify-content: flex-end;
						align-items: center;
					}
					.btn_box {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding-left: 15px;
						// flex: 1;
						width: 244px;
						max-width: 244px;

						.uploadFile {
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
				}
			}
		}
	}
</style>